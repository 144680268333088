<!--  隔离点统计  By阿旭 -->
<template>
  <div class="main">
    <div class="cantent-box">
      <div>
        <span>检测机构:</span>&nbsp;&nbsp;&nbsp;
        <el-select clearable value-key="orgCode" v-model="acidOrg" placeholder="请选择" size="medium">
          <el-option v-for="item in acidList" :key="item.orgCode" :label="item.orgName" :value="item.orgCode">
          </el-option>
        </el-select>
        <!-- 检测时间范围 -->
        <span style="margin:0 20px;">检测时间范围:</span>
        <el-date-picker unlink-panels v-model="pickerTime" size="medium" type="daterange" value-format="yyyy-MM-dd"
          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
        <el-button type="primary" size="medium" @click="queryInfo('btn')" :loading="loading"
          style="margin: 0px 0 20px 20px;">查询</el-button>
        <el-button v-btn-permission="'008013003001'" type="primary" size="medium" @click="escalateExport('btn')"
          v-loading.fullscreen.lock="fullscreenLoading" :loading="exportLoading"
          style="margin: 0px 0 20px 20px;">导出</el-button>
        <div class="main-box">
          <div class="showbox">
            <span>已检测</span>
            <div class="bottom-box">
              <span class="countStyle">{{ this.covidSum.detectedShareCount }}</span>
              <span>(份)</span>
              <span class="countStyle">{{ this.covidSum.detectedPeopleCount }}</span>
              <span>(人)</span>
            </div>
          </div>
          <div class="showbox">
            <span>已报告</span>
            <div class="bottom-box">
              <span class="countStyle">{{ this.covidSum.reportedShareCount }}</span>
              <span>(份)</span>
              <span class="countStyle">{{ this.covidSum.reportedPeopleCount }}</span>
              <span>(人)</span>
            </div>
          </div>
          <div class="showbox">
            <span>未报告</span>
            <div class="bottom-box">
              <span class="countStyle">{{ this.covidSum.notReportedShareCount }}</span>
              <span>(份)</span>
              <span class="countStyle">{{ this.covidSum.notReportedPeopleCount }}</span>
              <span>(人)</span>
            </div>
          </div>
          <div class="showbox">
            <span>阴性结果</span>
            <div class="bottom-box">
              <span class="countStyle">{{ this.covidSum.negativeShareCount }}</span>
              <span>(份)</span>
              <span class="countStyle">{{ this.covidSum.negativePeopleCount }}</span>
              <span>(人)</span>
            </div>
          </div>
          <div class="showbox">
            <span>阳性结果</span>
            <div class="bottom-box">
              <span class="countStyle">{{ this.covidSum.positiveShareCount }}</span>
              <span>(份)</span>
              <span class="countStyle">{{ this.covidSum.positivePeopleCount }}</span>
              <span>(人)</span>
            </div>
          </div>
          <div class="showbox">
            <span>可疑结果</span>
            <div class="bottom-box">
              <span class="countStyle">{{ this.covidSum.suspiciousShareCount }}</span>
              <span>(份)</span>
              <span class="countStyle">{{ this.covidSum.suspiciousPeopleCount }}</span>
              <span>(人)</span>
            </div>
          </div>
        </div>

        <div class="content">
          <table>
            <tr>
              <!-- <td v-for="item in tdList" :key="item.value"  :class="{ 'td-width': item.value != 1 }">{{ item.label }}</td> -->
              <td rowspan="2">采样机构</td>
              <td colspan="2">已检测</td>
              <td colspan="2">已报告</td>
              <td colspan="2">未报告</td>
              <td colspan="2">阴性结果</td>
              <td colspan="2">阳性结果</td>
              <td colspan="2">可疑结果</td>
            </tr>
            <tr>
              <td>份</td>
              <td>人</td>
              <td>份</td>
              <td>人</td>
              <td>份</td>
              <td>人</td>
              <td>份</td>
              <td>人</td>
              <td>份</td>
              <td>人</td>
              <td>份</td>
              <td>人</td>
            </tr>
            <tr v-for="(item, v) in covidDetailedList" :key="v">
              <td>{{ item.samplingOrgName }}</td>
              <td class="td-width">{{ item.detectedShareCount || 0 }}</td>
              <td class="td-width">{{ item.detectedPeopleCount || 0 }}</td>
              <td class="td-width">{{ item.reportedShareCount || 0 }}</td>
              <td class="td-width">{{ item.reportedPeopleCount || 0 }}</td>
              <td class="td-width">{{ item.notReportedShareCount || 0 }}</td>
              <td class="td-width">{{ item.notReportedPeopleCount || 0 }}</td>
              <td class="td-width">{{ item.negativeShareCount || 0 }}</td>
              <td class="td-width">{{ item.negativePeopleCount || 0 }}</td>
              <td class="td-width">{{ item.positiveShareCount || 0 }}</td>
              <td class="td-width">{{ item.positivePeopleCount || 0 }}</td>
              <td class="td-width">{{ item.suspiciousShareCount || 0 }}</td>
              <td class="td-width">{{ item.suspiciousPeopleCount || 0 }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { importFile, messageBox } from "@/utils/utils";
import { exportCovidCheckStatistics, covidCheckStatistics } from "../../api/StatisticalAnalysis/dailySummaryStatistics";
import IsolateTabel from './modules/IsolateTabel';
import { mapState } from "vuex";
import moment from "moment"
import { getBusinessOrgList } from '../../api/SystemManagement/index.js'


export default {
  components: { IsolateTabel },
  data() {
    return {
      exportLoading: false,
      acidOrg: "",
      acidList: [],
      covidDetailedList: [],
      covidSum: [],
      loading: false,
      fullscreenLoading: false,
      props: { multiple: true },
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      extantIsolateList: [],
      ids: [],
      pickerTime: [],
    };
  },
  created() {
    let timeNow = moment().format('YYYY-MM-DD');
    this.pickerTime[0] = `${timeNow}`
    this.pickerTime[1] = `${timeNow}`
    this.acidOrgList()
  },

  methods: {
    // 核酸检测业务机构
    async acidOrgList() {
      let params = { nucleicAcid: 1 }
      let { data } = await getBusinessOrgList({ params });
      this.acidList = data.data;
      if (this.acidList.length === 1) {
        this.acidOrg = this.acidList[0].orgCode
        this.getCovidCheckStatistics();
      }
    },

    // 设置请求参数
    setParams() {
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
      };
      if (this.acidOrg) {
        params.detectionOrgCode = this.acidOrg
      }
      if (this.pickerTime && this.pickerTime.length == 2) {
        params.detectionStartTime = this.pickerTime[0];
        params.detectionEndTime = this.pickerTime[1];
      }
      return params;
    },

    // 查询
    queryInfo() {
      this.loading = true;
      this.getCovidCheckStatistics();
    },
    // 统计列表
    async getCovidCheckStatistics() {
      let params = this.setParams();
      // console.log(params,'打印参数')
      let result = await covidCheckStatistics({ params })
      // console.log(result, '打印result')
      if (result.status === 200) {
        this.loading = false;
      }
      if (result.data.code === "200") {
        this.covidDetailedList = result.data.data.covidCheckDetailedList
        this.covidSum = result.data.data.covidCheckSum
      }
    },
    // 导出
    escalateExport() {
      let params = this.setParams()
      // console.log(params, "数据外的的params");
      messageBox(
        () => {
          this.exportLoading = true;
          exportCovidCheckStatistics({ params }).then((res) => {
            if (res.status === 200) {
              this.exportLoading = false;
            }
            importFile(res.data, "核酸检测统计报表导出");
          });
        },
        "warning",
        "确定导出表格中的数据吗？"
      );
      // exportTransportRegList()
    },
  }
};
</script>
  
<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;
}

.top {
  height: 30px;
  font-size: 18px;
  line-height: 40px;
  font-weight: bold;
  color: #666666;
  display: flex;
  justify-content: space-between;
}

.cantent-box {
  margin-top: 10px;

  .subtitle {
    color: #333333;
    margin-bottom: 16px;
  }
}

.showbox {
  width: 200px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 20px 20px 0;
  box-shadow: 0px 2px 8px 0.5px rgba(0, 0, 0, 0.2);
}

.main-box {
  display: flex;
}

.bottom-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.content {
  margin-right: 20px;

  table {
    font-size: 14px;
    border-collapse: collapse;

    tr:nth-child(1) {
      td {
        background: #f5f5f5;
        font-weight: bold;
      }
    }

    tr:nth-child(2) {
      td {
        background: #f5f5f5;
        font-weight: bold;
      }
    }

    td {
      width: 220px;
      height: 40px;
      opacity: 1;
      background: #fff;
      color: #333333;
      border: 1px solid #ccc;
      text-align: center;
    }

    tr .td-width {
      width: 140px !important;
    }
  }
}

.countStyle {
  font-size: 18px;
  font-weight: bolder;
}
</style>
  